import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MyThemeContext } from "../../context/theme-context";
import { SettingsContext } from "../../context/settings-context";
import { Web3Context } from "../../context/web3-context";

import { AppBar, Box, Toolbar, Typography, IconButton, Tabs, Tab, Avatar, useMediaQuery, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import { green, red, grey } from "@mui/material/colors";

import DrawerComponent from "./DrawerComponent";
import SettingsMenu from "./SettingsMenu";
import WalletsMenu from "./WalletsMenu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsightsIcon from "@mui/icons-material/Insights";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Donate from "../Common/Donate";

const links = [
	{ id: 0, name: "Dashboard", url: "/dashboard", icon: <DashboardIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 1, name: "Stats", url: "/stats", icon: <InsightsIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 2, name: "Market", url: "/market", icon: <LocalGroceryStoreIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 3, name: "Map", url: "/map", icon: <LocationOnIcon sx={{ width: 45, height: 45 }} />, hide: false },
	// { id: 4, name: "Community", url: "/community", icon: <PeopleIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 8, name: "API Metrics", url: "/traffic", icon: <NetworkCheckIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 5, name: "Settings", url: "/settings", icon: <SettingsIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 7, name: "Help", url: "/help", icon: <HelpOutlineIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 6, name: "About", url: "/about", icon: <InfoIcon sx={{ width: 45, height: 45 }} />, hide: false },
	// { id: 7, name: "Training", url: "/training", icon: <ModelTrainingIcon sx={{ width: 45, height: 45 }} />, hide: false },
];

function Header() {
	const { themeContext } = useContext(MyThemeContext);
	const { blockchainsClient, settingsWallet, FRONT_END_NAME, FRONT_END_VERSION } = useContext(SettingsContext);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openSettingsMenu, setOpenSettingsMenu] = useState(null);
	const [openWalletsMenu, setOpenWalletsMenu] = useState(null);
	const [openDonate, setOpenDonate] = useState(false);
	const { account, connect, disconnect, active } = useContext(Web3Context);
	const [borderColor, setBorderColor] = useState("transparent");
	const { t } = useTranslation(); // Hook pour accéder aux traductions

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleWalletsClick = (event) => {
		setOpenWalletsMenu(event);
		setOpenSettingsMenu(null);
	};
	const handleSettingsClick = (event) => {
		setOpenSettingsMenu(event);
		setOpenWalletsMenu(null);
	};
	const handleSettingsClose = (event) => {
		setOpenSettingsMenu(null);
		setOpenWalletsMenu(null);
	};

	const attractiveColors = [
		"#39FF14", // Neon Green
		"#FF073A", // Neon Red
		"#0FF0FC", // Electric Cyan
		"#FF6EC7", // Neon Pink
		"#F4F400", // Neon Yellow
		"#F7A8B8", // Neon Light Pink
		"#B0FF2D", // Lime Green
		"#DFFF00", // Chartreuse Yellow
		"#CCFF00", // Fluorescent Lime
		"#FFD300", // Fluorescent Amber
		"#FE019A", // Hot Pink
		"#FF5F1F", // Neon Orange
		"#7FFF00", // Chartreuse
		"#DAF7A6", // Light Neon Green
		"#FF00FF", // Magenta
		"#00FF00", // Bright Neon Green
		"#FF1493", // Deep Pink
		"#00FA9A", // Medium Spring Green
		"#FF4500", // Orange Red
		"#7CFC00", // Lawn Green
	];

	// Function to randomly pick a color from the list
	const getRandomColor = () => {
		const randomIndex = Math.floor(Math.random() * attractiveColors.length);
		return attractiveColors[randomIndex];
	};

	useEffect(() => {
		// Random interval between 1 second and 15 seconds
		const minInterval = 15 * 1000; // 1 second
		const maxInterval = 5 * 60 * 1000; // 5 minutes
		const timeBlinking = 150; // Duration for each blink on
		const timeNoBlinking = 150; // Duration for each blink off (currently not used)

		// Function to handle the blinking effect
		const blinkEffect = (color) => {
			// Start with the selected color
			setBorderColor(color);

			// Blink sequence: alternating between the selected color and transparent
			setTimeout(() => {
				setBorderColor(color); // Keep the color
				setTimeout(() => {
					setBorderColor("transparent"); // Make the border transparent
					setTimeout(() => {
						setBorderColor(color); // Set back to color
						setTimeout(() => {
							setBorderColor("transparent"); // Make border transparent
							setTimeout(() => {
								setBorderColor(color); // Set back to color
								setTimeout(() => {
									setBorderColor("transparent"); // Final reset to transparent
								}, timeNoBlinking); // Third blink off
							}, timeBlinking); // Third blink on
						}, timeNoBlinking); // Second blink off
					}, timeBlinking); // Second blink on
				}, timeNoBlinking); // First blink off
			}, timeBlinking); // First blink on
		};

		// Function to change the color and trigger blinking
		const changeColor = () => {
			const newColor = getRandomColor(); // Get a random attractive color
			blinkEffect(newColor); // Trigger the blink effect with the new color
		};

		// Set up an interval to randomly trigger the color change and blinking effect
		const interval = setInterval(changeColor, Math.floor(Math.random() * (maxInterval - minInterval) + minInterval));

		// Clean up the interval on component unmount
		return () => clearInterval(interval);
	}, []);

	if (!blockchainsClient) return <></>;
	if (!settingsWallet) return <></>;
	const chainUsed = settingsWallet ? blockchainsClient[settingsWallet.selectedBlockchain] : blockchainsClient["Gnosis"];

	const logo_size = 30;
	const logo_pitsbi_sizeW = 40;
	const logo_pitsbi_sizeH = 50;
	const pitsrmm_sizeW = 50;
	const pitsrmm_sizeH = 50;
	const imgPitsRMM = "/images/pits-RMM.png";

	return (
		<Box sx={{ width: 1, height: 64 }}>
			<Donate open={openDonate} setOpen={setOpenDonate} />
			<DrawerComponent openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} links={links} />
			<SettingsMenu open={openSettingsMenu} menuClose={handleSettingsClose} />
			<WalletsMenu open={openWalletsMenu} menuClose={handleSettingsClose} />
			<AppBar>
				<Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
					{isMatch && (
						<IconButton color='inherit' onClick={() => setOpenDrawer(true)}>
							<MenuIcon />
						</IconButton>
					)}

					<Box sx={{display:"flex",alignItems:"center", cursor: "pointer" }}  onClick={() => setOpenDrawer(true)}>
						<Avatar
							alt='Logo'
							src='/logo-pitsbi2.png'
							sx={{ ml: 1, p: 0, width: logo_pitsbi_sizeW, height: logo_pitsbi_sizeH, borderRadius: 0, border: 0}}
							// onClick={() => setOpenDrawer(true)}
						/>

						<Typography variant='h6' color='inherit' sx={{ ml: 1, }}>
							{FRONT_END_NAME}
							{/* {FRONT_END_VERSION} */}
						</Typography>
					</Box>

					{!isMatch && (
						<Tabs value={window.location.pathname} textColor='inherit' indicatorColor='secondary' sx={{ ml: "auto" }}>
							{links
								// .filter((link) => link.hide !== true)
								.map(
									(link, index) =>
										link.hide !== true && <Tab key={index} tabIndex={index} component={Link} label={link.name} value={link.url} to={link.url} />
								)}
						</Tabs>
					)}

					{/* <ConnectButton /> */}

					{chainUsed ? (
						<IconButton sx={{ ml: "auto" }}>
							<Avatar alt={chainUsed.name} src={chainUsed.image} fontSize='small' sx={{ width: 30, height: 30 }} />
						</IconButton>
					) : (
						<IconButton sx={{ ml: "auto" }}>
							<Avatar alt='Logo' src='/logo192.png' sx={{ width: logo_size, height: logo_size }} />
						</IconButton>
					)}

					<IconButton color='inherit' onClick={(e) => handleWalletsClick(e)}>
						<AccountBalanceWalletIcon sx={{ width: logo_size, height: logo_size }} />
					</IconButton>

					<IconButton color='inherit' onClick={(e) => handleSettingsClick(e)}>
						<SettingsIcon sx={{ width: logo_size, height: logo_size }} />
					</IconButton>

					<IconButton color='inherit' sx={{ p: 0 }} onClick={() => setOpenDonate(true)}>
						{/* <CardGiftcardIcon sx={{ width: 25, height: 25 }} /> */}
						<Avatar
							alt='Logo'
							src={imgPitsRMM}
							sx={{
								p: 0,
								mr: 1,
								width: pitsrmm_sizeW,
								height: pitsrmm_sizeH,
								border: 3,
								borderColor: borderColor, // Apply the random border color
								borderRadius: 9,
							}}
						/>
					</IconButton>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default Header;
